import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { LoginFlow, UI } from '../types/flow_login'
import FormMethodPassword from '../forms/FormMethodPassword'
import { PageProps } from "./props";

export default function PageVerify(props: PageProps) {

    let [searchParams, _] = useSearchParams();
    let [currentUI, setCurrentUI] = useState<UI | null>(null);

    const redirectToDashboard = () => window.location.assign("/dashboard")

    useEffect(() => {
        const resolvedFlowID = searchParams.get("flow")
        if (resolvedFlowID === null) {
            redirectToDashboard()
        } else {
            axios.get<LoginFlow>(props.backendServices.verification(resolvedFlowID), {
                withCredentials: true,
            }).then(response => {
                console.log(response.data.ui)
                setCurrentUI(response.data.ui)
            }).catch((error: AxiosError) => {
                if (error.status === 404) {
                    redirectToDashboard()
                }
            })
        }
    }, [])

    return (
        <div className="content">
            {currentUI === null
                ? <></>
                : 
                    <>
                        <h2 className="bp3-heading">Verify your account</h2>
                        <FormMethodPassword ui={currentUI} submitButtonLabel="Verify" canRecoverFromHere={false} />
                    </>
            }
        </div>
    )
}
