import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';

import { Session } from '../types/session'
import { PageProps } from './props';

export default function PageProfile(props: PageProps) {

    let [currentSession, setCurrentSession] = useState<Session | null>(null)

    useEffect(() => {
        axios.get<Session>(props.backendServices.whoami(), {
            withCredentials: true,
        }).then(response => {
            console.log(response.data)
            setCurrentSession(response.data)
        }).catch((error: AxiosError) => {
            console.error(error)
            // on 401, redirect to login
        })
    }, [])

    return (
        <div className="content">
            <h2 className="bp3-heading">Profile</h2>
            {currentSession === null
                ? <></>
                : <pre>{JSON.stringify(currentSession, null, 2) }</pre> }
        </div>
    )
}
