import axios from "axios"

interface BackendData {
    identity: string,
    token: string,
}

export class BackendServices {
    
    readonly data: BackendData
    readonly protocol: string
    readonly cookieDomain: string
    
    constructor(backendData: BackendData) {
        this.data = backendData
        this.cookieDomain = cookieDomain()
        this.protocol = window.location.protocol
    }

    loginInit() {
        return `${this.protocol}//${this.data.identity}${this.cookieDomain}/self-service/login/browser`
    }

    login(flowID: string) {
        return `${this.protocol}//${this.data.identity}${this.cookieDomain}/self-service/login/flows?id=${flowID}`
    }

    registrationInit() {
        return `${this.protocol}//${this.data.identity}${this.cookieDomain}/self-service/registration/browser`
    }

    registration(flowID: string) {
        return `${this.protocol}//${this.data.identity}${this.cookieDomain}/self-service/registration/flows?id=${flowID}`
    }

    verification(flowID: string) {
        return `${this.protocol}//${this.data.identity}${this.cookieDomain}/self-service/verification/flows?id=${flowID}`
    }

    whoami() {
        return `${this.protocol}//${this.data.identity}${this.cookieDomain}/sessions/whoami`
    }

}

const cookieDomain = (): string => {
    const hostname = window.location.hostname
    return hostname.substring(hostname.indexOf("."))
}

export const loadBackendServices = (): Promise<BackendServices> => {
    return new Promise<BackendServices>((resolve, reject) => {
        axios.get<BackendData>("/backend").then(response => {
            resolve(new BackendServices(response.data))
        }).catch(e => reject(e))
    })
}
