const getLabelByName = (fieldName: string): string => {
    switch (fieldName) {
        case "current-password":
            return "Current password";
        case "password":
            return "Password";
        case "email":
            return "Email address of the account to recover"
        case "identifier":
        case "traits.email":
            return "Email address";
        case "traits.name_first":
            return "First name";
        case "traits.name_last":
            return "Last name";
        case "traits.invite_code":
            return "Invite code";
        default:
            return fieldName;
    }
}

export default getLabelByName
