import { FormProps } from './props'
import { UI, UIMessage, UINode } from '../types/flow_login'
import getLabelByName from './labels'

import { Button, ButtonGroup, FormGroup, Icon, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

// Tooltip2 causes
// findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of TransitionGroup which is inside StrictMode
// Tracking issue: https://github.com/palantir/blueprint/issues/3979#issuecomment-1222989336
import { Tooltip2 } from "@blueprintjs/popover2";

export default function FormMethodPassword(props: FormProps) {

    const passwordLogin = (ui: UI): UINode[] => ui.nodes.filter(node => node.group === "default" || node.group === "password")

    const flattenFormFieldMessages = (messages: UIMessage[] | undefined): string => {
        if (messages) {
            return messages.map(message => message.text).join(", ")
        }
        return ""
    }

    const fieldErrorIndicator = (formField: UINode) => {
        if (formField.messages === undefined || formField.messages === null) {
            return undefined
        }
        if (formField.messages.length > 0) {
            return (<Tooltip2 content={flattenFormFieldMessages(formField.messages)}><Button icon={IconNames.ERROR} /></Tooltip2>)
        }
        return undefined
    }

    return (
        <>
            <form method={props.ui.method} action={props.ui.action}>
                <input type="hidden" name="method" value="password" />
                {
                    props.ui.messages && props.ui.messages.length > 0
                    ?
                    <div className="formError">
                        <ul>
                            { props.ui.messages.map((message, index) => {
                                return (
                                    <li>{message.text}</li>
                                )
                            })}
                        </ul>
                    </div>
                    : <></>
                    
                }

                { passwordLogin(props.ui).map((node, index) => {
                    return (
                        <>
                            {
                                node.attributes.node_type === "input"
                                ?
                                <>{
                                    node.attributes.type === "submit" && <></> // don't render default submit buttons
                                    || node.attributes.type === "hidden" && <input type="hidden" id={node.attributes.name} name={node.attributes.name} value={node.attributes.value} />
                                    || 
                                        <FormGroup label={getLabelByName(node.attributes.name)}>
                                            <InputGroup name={node.attributes.name}
                                                id={"profile"+node.attributes.name}
                                                type={node.attributes.type}
                                                defaultValue={node.attributes.value}
                                                //onChange={formFieldChange}
                                                intent={node.messages && node.messages.length > 0 ? "warning" : "none"}
                                                rightElement={fieldErrorIndicator(node)}
                                                leftIcon={node.attributes.required ? <Icon icon={IconNames.ASTERISK} /> : undefined}
                                                />
                                        </FormGroup>
                                }</>
                                :
                                <></>
                            }
                        </>
                    )
                })}
                <ButtonGroup>
                    <Button type="submit" text={props.submitButtonLabel} intent="primary" />
                    {
                        props.canRecoverFromHere
                        ?
                        <Button icon={IconNames.REFRESH} intent="danger" text="Forgot password"
                            //onClick={() => setWantsRecovery(true)}
                            />
                        : <></>
                    }
                </ButtonGroup>
            </form>
        </>
    )

}
