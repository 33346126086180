import {
    Link,
} from "react-router-dom";

export default function Root() {
    return (
        <div className="content">
            <h2>Welcome to this example application</h2>
            <br />
            <Link to="/dashboard/">Dashboard</Link>
            <br />
        </div>
    )
}
