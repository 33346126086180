import React, { useEffect } from 'react';
import '../index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { useState } from "react";
import { loadBackendServices, BackendServices } from "../backend/backend";

import Dashboard from '../pages/Dashboard'
import PageProfile from '../pages/PageProfile'
import PageSignIn from '../pages/PageSignIn'
import PageSignUp from '../pages/PageSignUp'
import PageVerify from '../pages/PageVerify'
import Root from '../pages/Root'

export default function App() {

    const [backendServices, setBackendServices] = useState<BackendServices | null>(null)

    const getConcreteRouter = (backendServices: BackendServices) => {
        return createBrowserRouter([
            {
                path: "/",
                element: <Root />,
            },
            {
                path: "dashboard/",
                element: <Dashboard backendServices={backendServices} />,
            },
            {
                path: "profile/",
                element: <PageProfile backendServices={backendServices} />,
            },
            {
                path: "sign-in/",
                element: <PageSignIn backendServices={backendServices} />,
            },
            {
                path: "sign-up/",
                element: <PageSignUp backendServices={backendServices} />,
            },
            {
                path: "verify/",
                element: <PageVerify backendServices={backendServices} />,
            },
        ]);
    }

    useEffect(() => {
        loadBackendServices().then(res => setBackendServices(res)).catch()
    }, [])

    return (
        <>
        {
            backendServices ?
            <React.StrictMode>
                <RouterProvider router={getConcreteRouter(backendServices)} />
            </React.StrictMode>
            : <div>loading...</div>
        }
        </>
    )
}
